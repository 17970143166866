import React from 'react'
  import { MDXTag } from '@mdx-js/tag'


  import DefaultLayout from "/opt/build/repo/src/cmsPages/index.js";
const layoutProps = {};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = DefaultLayout;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" Layout={this.layout} layoutProps={Object.assign({}, layoutProps, props)} components={components}>

      <MDXTag name="p" components={components}>{`Terraform is an Infrastructure-as-code (IaC) tool made by HashiCorp. `}</MDXTag>
      <MDXTag name="p" components={components}>{`You write HashiCorp Configuration Lanugage (HCL) code, which is nice and declarative, and Terraform will create, modify, or delete infrastructure for you.`}</MDXTag>
      <MDXTag name="p" components={components}>{`It's good because it lets you check your code into version control and monitor changes, instead of just clicking around randomly in the AWS console. It also offers modules which makes infrastructure more repeatable and extendable.`}</MDXTag>
      <MDXTag name="p" components={components}>{`It can be challenging to learn HCL and to develop a workflow around Terraform files, instead of clicking around in the AWS/GCP/Azure console which might be easier for learning at first.`}</MDXTag>
           </MDXTag>;
  }

}
MDXContent.isMDXComponent = true;
export const _frontmatter = {};
      